<template>
    <div>
        <h1 class="text-center text-primary">
            Carga de inventario con archivo
        </h1>
        <div class="mt-4">
            <span>
                En esta sección puedes cargar, actualizar el inventario en el
                comercio seleccionado en la parte superior. A continuación te
                presentamos dos procesos que te permitirán subir el inventario
                al sistema con un archivo de forma fácil y rápida.
            </span>
        </div>

        <div class="container mt-5">
            <div class="row mt-4">
                <div class="col-md-6">
                    <router-link
                        to="/app/inventories/massive/load"
                        class="text-decoration-none"
                    >
                        <div
                            class="button-card text-base rounded border-primary border px-3 py-3"
                            style="cursor: pointer"
                        >
                            <div class="">
                                <h5 class="text-primary">
                                    Carga masiva de inventario 🧮
                                </h5>
                                <span>
                                    Permite cargar de inventario al sistema con
                                    las siguientes consideraciones:
                                </span>
                                <br />
                                <ul class="list-group ml-4">
                                    <li class="">
                                        Utilizando el código de barras de la
                                        referencia como clave, identifica si la
                                        referencia existe y esta en el
                                        inventario del comercio.
                                    </li>
                                    <li class="">
                                        En caso de que la referencia no este
                                        creada en el sistema el proceso la
                                        omite.
                                    </li>
                                    <li class="">
                                        En caso de que la referencia no este en
                                        el inventario se crea la referencia en
                                        el inventario de comercio.
                                    </li>
                                    <li class="">
                                        En caso de que la referencia este en el
                                        inventario de comercio permite aumentar
                                        las unidades o hacer un replazo directo
                                        de las unidades.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InventoriesMassive"
};
</script>
